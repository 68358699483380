module.exports = function () {

	'use-strict';

	const config = require('../config.js');

	// const navigation = require('./navigation.js');
	//
	// window.addEventListener('load', function(){
	// 	let nav = navigation.init();
	// });

	const mega_lightbox_init = require('./mega-lightbox.js');
	const switcher_init = require('./switcher.js');
	const navigation_init = require('./navigation.js');

	window.addEventListener('load', function(){
		let mega_lightbox = mega_lightbox_init.init();
		let switcher = switcher_init.init();
		let navigation = navigation_init.init();
	});

};
