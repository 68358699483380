module.exports.init = function () {

	'use-strict';

	const s = {
		switchers: document.querySelectorAll('.js-switcher'),
		body: document.body,
	};

  Array.prototype.forEach.call(s.switchers, function (switcher) {

    const list =  switcher.querySelectorAll('.js-switcher-list')[0];
    const buttons =  switcher.querySelectorAll('.js-switcher-button');
    const slides =  switcher.querySelectorAll('.js-switcher-slide');

		if (!list) return;

    list.style.height = get_tallest_slide_height(slides) + 'px';

    Array.prototype.forEach.call(buttons, function (button) {
      button.addEventListener('click', on_button_click);
    });

    Array.prototype.forEach.call(slides, function (slide) {

    });


    function on_button_click(e) {
      const slide_id = e.target.getAttribute('data-slide-id');
      show_slide_by_id(slide_id);
      show_active_button_by_id(slide_id);
    }


    function  get_tallest_slide_height(slides) {

      let height = 0;

      Array.prototype.forEach.call(slides, function (slide) {
        console.log(slide);
        if (slide.clientHeight > height) {
          height = slide.clientHeight;
        }
      });

      return height;

    }


    function show_slide_by_id(id) {
      Array.prototype.forEach.call(slides, function (slide) {
        if (slide.getAttribute('data-slide-id') === id ) {
          slide.classList.add('is-active');
        } else {
          slide.classList.remove('is-active');
        }
      });
    }


    function show_active_button_by_id(id) {
      Array.prototype.forEach.call(buttons, function (button) {
        if (button.getAttribute('data-slide-id') === id ) {
          button.classList.add('is-active');
        } else {
          button.classList.remove('is-active');
        }
      });
    }

  });


	// function open() {
  //
	// }
  //
  //
	// function close() {
  //
	// }


	return {
		// open,
		// close
	};

};
