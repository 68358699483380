var whichAnimationEvent = require("../vendor/modernizr/whichAnimationEvent.js");

module.exports.init = function () {

	'use-strict';

	const s = {
		header: document.querySelectorAll('.js-header')[0]
	};

  var last_scroll = 0;
  var header_y = 0;
  var header_h = s.header.scrollHeight;

  window.addEventListener("scroll",function() {

    if (window.scrollY === 0) {
      s.header.classList.remove("is-active"); // you may prefer s.header.classList.remove("fixed")
      last_scroll = s.header.style.top = 0;
      return;
    }

		if (document.body.classList.contains('home')) {
			if (window.scrollY > window.innerHeight - header_h) {
				s.header.classList.remove('l-header--home');
			} else {
				s.header.classList.add('l-header--home');
			}
		}


    s.header.classList.add("is-active"); // you may prefer s.header.classList.add("fixed")
    header_y = header_y - (window.scrollY-last_scroll);
    header_y = Math.min(header_y,0);
    header_y = Math.max(header_y,-header_h);
    s.header.style.top = header_y + "px";
    last_scroll = window.scrollY;

  });

	return {
		// open,
		// close
	};

};
