(function () {
	"use strict";

	var init = require('./components/init'),
		Choices = require('choices.js');

		window.addEventListener('load', function(){
			const options = {
				search: false,
			};
			// const choices = new Choices('.js-select', options);
		});



	init();

})();
