var whichAnimationEvent = require("../vendor/modernizr/whichAnimationEvent.js"),
    tingle = require('tingle.js'),
    fitvids = require('fitvids');

module.exports.init = function () {

	'use-strict';

	const s = {
		triggers: document.querySelectorAll('.js-mega-lightbox-trigger'),
		body: document.body,
	};

	const animationEvent = whichAnimationEvent();

  let mega_lightbox = new tingle.modal({
      footer: false,
      stickyFooter: false,
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: "Close",
      cssClass: ['c-mega-lightbox'],
      onOpen: function() {
          // console.log('modal open');
      },
      onClose: function() {
          // console.log('modal closed');

      },
      beforeClose: function() {
        var player = document.getElementById('player');
        console.log('player',player);
        player.src = '';
          // here's goes some logic
          // e.g. save content before closing the modal
        return true; // close the modal
      	// return false; // nothing happens
      }
  });


  if ( s.triggers.length ) {
    Array.prototype.forEach.call(s.triggers, function (trigger) {
      trigger.addEventListener('click', open_mega_lightbox);
    });
  }


  function open_mega_lightbox(e) {

    console.log( this.getAttribute('data-video-id'));

    const video_id = this.getAttribute('data-vided-id');

    let modal_content = `
    <iframe id="player" src="https://player.vimeo.com/video/${video_id}?color=ff6060&title=0&byline=0&portrait=0"
      width="640" height="360" frameborder="0"
      webkitallowfullscreen mozallowfullscreen allowfullscreen>
    </iframe>`;

    mega_lightbox.setContent(modal_content);
    mega_lightbox.open();

    fitvids();

    e.preventDefault();
    return false;

  }



  // set content


  // open modal



	function open() {

	}

	function close() {

	}

	return {
		// open,
		// close
	};

};
